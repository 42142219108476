import styled from 'styled-components';

const Display = styled.div`
  ${({ breakpoints }) => {
    if (breakpoints.includes('mobile')) {
      return `
        display: block;
      `;
    }
    return 'display: none;';
  }}
  ${({ breakpoints }) => {
    if (breakpoints.includes('tablet')) {
      return `
        @media screen and (min-width: 768px) {
          display: block;
        }
      `;
    }
    return `
      @media screen and (min-width: 768px) {
        display: none;
      }
    `;
  }}
  ${({ breakpoints }) => {
    if (breakpoints.includes('desktop')) {
      return `
        @media screen and (min-width: 1024px) {
          display: block;
        }
      `;
    }
    return `
      @media screen and (min-width: 1024px) {
        display: none;
      }
    `;
  }}
`;

export default Display;
