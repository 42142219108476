import { createGlobalStyle } from 'styled-components';
import { BLACK } from 'constants/colors';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Raleway', sans-serif;
    margin: 0;
  }
  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }
  p {
    line-height: 1.6;
  }
  h1 {
    font-size: 35px;
    font-weight: 500;
  }
  h2 {
    font-size: 29px;
    font-weight: 500;
  }
  h3 {
    font-size: 24px;
    font-weight: 500;
  }
  h4 {
    font-size: 20px;
    font-weight: 500;
  }
  h5 {
    font-size: 16px;
    font-weight: 500;
  }
  h6 {
    font-size: 14px;
    font-weight: 600;
  }
  small {
    font-size: 12px;
  }
  b {
    font-weight: 700;
  }
  a {
    color: ${BLACK.base};
  }
`;

export default GlobalStyle;
