/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { BLACK } from 'constants/colors';

export const Badge = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  border: 4px solid ${BLACK.base};
  border-radius: 28px;
  font-family: 'Bubblegum Sans', cursive;
  background-color: #fff;
`;
