import React from 'react';
import { PrimaryButton } from 'components/shared/button';
import Display from 'components/shared/Display';

const SignUpButton = ({ sm }) => <div>
  <a href="http://eepurl.com/g2Yw2H" target='_blank' rel="noopener noreferrer">
    <PrimaryButton sm={sm}>
      <Display breakpoints={['mobile']}>
        Join
      </Display>
      <Display breakpoints={['tablet', 'desktop']}>
        Join newsletter
      </Display>
    </PrimaryButton>
  </a>
</div>;

export default SignUpButton;
