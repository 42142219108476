import React from 'react';
import { SecondaryButton } from 'components/shared/button';
import { useHistory } from 'react-router-dom';

const SeeRecipesButton = ({ sm }) => {
  const history = useHistory();
  return <div>
    <SecondaryButton sm={sm} onClick={(e) => {
      e.preventDefault();
      history.push('/recipes');
    }}>See recipes</SecondaryButton>
  </div>;
};

export default SeeRecipesButton;
