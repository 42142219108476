import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { Box, Flex } from 'rebass';
import Nav from 'components/shared/Nav';
import Loading from 'components/shared/Loading';
import { PrimaryButton } from 'components/shared/button';
import { DoubleBorderCard } from './shared/card';
import { BLUE } from '../constants/colors';

const RecipeCard = styled(DoubleBorderCard)`
  width: calc(100vw - 64px);
  @media screen and (min-width: 768px) {
    width: 240px;
  }
  &:hover {
    background-color: #fcfcfc;
  }
`;

const RecipeName = styled.div`
  font-family: 'Bubblegum Sans', cursive;
  font-size: 24px;
`;

const RecipeSubTitle = styled.div`
  font-size: 12px;
`;

const Recipes = () => {
  const [result, setResult] = useState(null);
  useEffect(() => {
    axios.get('https://api.picturecook.com/recipes')
      .then((response) => {
        const { data } = response;
        setResult(data && data.result);
      });
  }, []);
  if (!result) {
    return (
      <Flex sx={{ height: 'calc(100vh - 150px)' }} alignItems="center" justifyContent="center">
        <Loading />
      </Flex>
    );
  }
  return (
    <Box>
      <Nav />
      <Box>
        <Box pb="32px">
          <h1>Recipes</h1>
        </Box>
        <Flex flexWrap="wrap">
          {result && result.map((recipe) => (
            <Box key={recipe.id} pr={['0px', '16px']} pb="16px">
              <RecipeCard>
                <Box p="8px">
                  <Box pb="24px">
                    <Box pb="8px">
                      <RecipeName key={recipe.id}>
                        {recipe.name}
                      </RecipeName>
                    </Box>
                    <Box pb="16px">
                      <RecipeSubTitle>
                        By {recipe.author} / {recipe.duration} / Serves {recipe.servingSize}
                      </RecipeSubTitle>
                    </Box>
                    <div>
                      {recipe.description}
                    </div>
                  </Box>
                  <a href={`/recipes/${recipe.id}`}>
                    <PrimaryButton sm>
                      View
                    </PrimaryButton>
                  </a>
                </Box>
              </RecipeCard>
            </Box>
          ))}
        </Flex>
      </Box>
    </Box>
  );
};

export default Recipes;
