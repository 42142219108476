/* eslint-disable import/prefer-default-export */
/* eslint-disable no-unused-vars */
const GREY = {
  lightest: '#F9F9FB',
  light: '#E4E7EB',
  base: '#425A70',
  dark: '#234361',
};
export const BLUE = {
  lightest: '#F7F9FD',
  light: '#DDEBF7',
  base: '#1070CA',
  dark: '#084B8A',
};
const RED = {
  lightest: '#FEF6F6',
  light: '#FAE2E2',
  base: '#EC4C47',
  dark: '#BF0E08',
};
const ORANGE = {
  lightest: '#FDF8F3',
  light: '#FAE3CD',
  base: '#D9822B',
  dark: '#95591E',
};
const YELLOW = {
  lightest: '#FEF8E7',
  light: '#FBE6A2',
  base: '#F7D154',
  dark: '#7E6514',
};
const GREEN = {
  lightest: '#F1FAF5',
  light: '#D4EEE2',
  base: '#47B881',
  dark: '#00783E',
};
const TEAL = {
  lightest: '#F1FBFC',
  light: '#D2EEF3',
  base: '#14B5D0',
  dark: '#007489',
};
const PURPLE = {
  lightest: '#F8F7FC',
  light: '#EAE7F8',
  base: '#735DD0',
  dark: '#37248F',
};

export const PRIMARY = GREEN;
export const BLACK = {
  base: '#282828',
};
