import styled from 'styled-components';
import borderSingle from 'assets/borders/border_single.png';
import borderDouble from 'assets/borders/border_double.png';

export const SingleBorderCard = styled.div`
  border-color: white;
  border-radius: 8px;
  border-style: solid;
  border-width: 16px;
  border-image: url(${borderSingle}) 30 stretch;
`;
export const DoubleBorderCard = styled.div`
  border-color: white;
  border-radius: 8px;
  border-style: solid;
  border-width: 16px;
  border-image: url(${borderDouble}) 30 stretch;
`;
export const FrameBorderCard = styled.div`
  border-color: white;
  border-radius: 8px;
  border-style: solid;
  border-width: 16px;
  border-image: url(${borderSingle}) 60 stretch;
`;
