import React from 'react';
import styled from 'styled-components';
import { Box, Flex } from 'rebass';
import {
  SingleBorderCard,
  DoubleBorderCard,
  FrameBorderCard,
} from 'components/shared/card';
import { Badge } from 'components/shared/badge';
import Time from 'components/shared/Time';

const STEP_HEIGHT = 240;

// Horizontal padding of step from edge of screen.
const H_PADDING = 160;
const MAX_STEP_WIDTH = 440;

// How wide screen would be when step width is maximised.
const SCREEN_WIDTH_FOR_MAX_STEP_WIDTH = MAX_STEP_WIDTH + H_PADDING;

const RecipeName = styled.div`
  font-family: 'Bubblegum Sans', cursive;
  font-size: 28px;
`;

const Col = (props) => <Box width={[1, 1 / 2]} pb="16px" px={['0px', '8px']} pl={['4px', '0px']} {...props} />;

const DescriptionOuter = styled.div`
  position: absolute;
  bottom: 0px;
  left: 20px;
  right: 20px;
  font-size: 10px;
  @media screen and (min-width: 375px) {
    /* iPhone 6/7/8 */
    font-size: 12px;
  }
  @media screen and (min-width: 414px) {
    /* iPhone 6/7/8 Plus */
    font-size: 14px;
  }
  @media screen and (min-width: ${SCREEN_WIDTH_FOR_MAX_STEP_WIDTH}px) {
    bottom: 8px;
    font-size: 16px;
  }
`;

const Description = ({ text }) => (
  <Flex flexWrap="wrap" justifyContent="center">
    {text}
  </Flex>
);

const StepCardOuter = styled(SingleBorderCard)`
  display: flex;
  min-height: ${({ scale }) => STEP_HEIGHT * scale}px;
`;

const Items = styled.div`
  position: relative;
  flex: 1;
  width: calc(100vw - 160px);
  height: ${({ scale }) => STEP_HEIGHT * scale}px;
  overflow-x: hidden;
  @media screen and (min-width: 768px) {
    width: 392px;
  }
`;

const ItemImg = styled.img`
  position: absolute;
  top: ${({ y, scale }) => y * scale}px;
  left: ${({ x, scale }) => x * scale}px;
  width: ${({ width, scale }) => width * scale}px;
  @media screen and (min-width: ${SCREEN_WIDTH_FOR_MAX_STEP_WIDTH}px) {
    top: ${({ y }) => y}px;
    left: ${({ x }) => x}px;
    width: ${({ width }) => width}px;
  }
`;

const StepCard = ({ idx, items, description }) => {
  const screenWidth = window.innerWidth || document.documentElement.clientWidth
    || document.body.clientWidth;
  const scale = Math.min((screenWidth - H_PADDING) / MAX_STEP_WIDTH, 1);
  return (
    <StepCardOuter style={{ position: 'relative' }} scale={scale}>
      <Badge style={{ position: 'absolute', top: -24, left: -24 }}><h3>{idx}</h3></Badge>
      <Box p="8px">
        <Items scale={scale}>
          {items && items.map((item) => (
            <ItemImg
              key={item.id}
              src={require(`../assets/${item.type}/${item.name}.svg`)}
              x={item.x}
              y={item.y}
              width={item.width}
              scale={scale}
            />
          ))}
        </Items>
        <DescriptionOuter><Flex justifyContent="center"><Description text={description} /></Flex></DescriptionOuter>
      </Box>
    </StepCardOuter>
  );
};

const Ingredient = ({ imgSrc, name }) => (
  <Box width={[1 / 2, 1 / 3]} pb="8px" px="8px">
    <Flex flexDirection="column" alignItems="center">
      <img alt={name} src={imgSrc} height="32px" />
      <Box pt="8px"><small>{name}</small></Box>
    </Flex>
  </Box>
);
const RecipeCard = ({
  name,
  author,
  duration,
  servingSize,
  description,
  ingredients,
  steps,
}) => {
  const mins = duration;
  return (
    <DoubleBorderCard>
      <Box p={['8px', '24px']}>
        <Flex flexDirection={['column', 'row']}>
          <Col pb="24px">
            <Box pb="16px">
              <Box pt="10px">
                <RecipeName>
                  {name}
                </RecipeName>
              </Box>
              <Flex flexDirection="row" alignItems="center">
                <small>By {author}</small>
                <Box px="8px">/</Box>
                <Flex style={{ marginTop: 8 }} flexDirection="row" alignItems="flex-start">
                  <Time mins={mins} sm />
                </Flex>
                <Box px="8px">/</Box>
                <small>Serves {servingSize}</small>
              </Flex>
            </Box>
            <p>{description}</p>
          </Col>
          <Col pb="24px">
            <FrameBorderCard>
              <Box p="8px">
                <Box pb="16px">
                  <h5>Ingredients</h5>
                </Box>
                <Flex flexWrap="wrap">
                  {ingredients && ingredients.map((ingredient) => (
                    <Ingredient
                      key={ingredient.name}
                      imgSrc={require(`../assets/ingredient/${ingredient.name}.svg`)}
                      name={ingredient.amount}
                    />
                  ))}
                </Flex>
              </Box>
            </FrameBorderCard>
          </Col>
        </Flex>
        <Box pb="24px">
          <h4>Steps</h4>
        </Box>
        <Flex flexWrap="wrap">
          {steps && steps.map((step, idx) => (
            <Col key={idx}>
              <StepCard
                idx={idx + 1}
                description={step.description}
                items={step.items}
              >
              </StepCard>
            </Col>
          ))}
        </Flex>
      </Box>
    </DoubleBorderCard>
  );
};

export default RecipeCard;
