import React from 'react';
import {
  Router,
  Route,
  Switch,
} from 'react-router-dom';
import styled from 'styled-components';
import { Flex, Box } from 'rebass/styled-components';
import FAQs from 'components/FAQs';
import Recipes from 'components/Recipes';
import Recipe from 'components/Recipe';
import Landing from 'components/Landing';
import Footer from 'components/shared/Footer';
import history from './history';
import GlobalStyle from './GlobalStyle';

const Container = styled.div`
  width: calc(100% - 32px);
  margin: 0 auto;
  @media screen and (min-width: 1024px) {
    width: 992px;
  }
`;

const App = () => (
  <Container>
    <Router history={history}>
      <GlobalStyle />
      <Flex style={{ height: '100vh' }} flexDirection="column">
        <Switch>
          <Route path="/recipes/:recipeId">
            <Recipe />
          </Route>
          <Route path="/recipes">
            <Recipes />
          </Route>
          <Route path="/faqs">
            <FAQs />
          </Route>
          <Route path="*">
            <Landing />
          </Route>
        </Switch>
        <Box pt="48px" pb="72px">
          <Footer />
        </Box>
      </Flex>
    </Router>
  </Container>
);

export default App;
