import React, { useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import startCase from 'lodash.startcase';
import axios from 'axios';
import { Box, Flex } from 'rebass';
import Nav from 'components/shared/Nav';
import RecipeCard from 'components/RecipeCard';
import { SecondaryButton } from 'components/shared/button';
import { withRouter, Link } from 'react-router-dom';

const Recipe = ({ match: { params } }) => {
  const [result, setResult] = useState(null);
  useEffect(() => {
    axios.get('https://api.picturecook.com/recipes')
      .then((response) => {
        const { data } = response;
        setResult(data && data.result);
      });
  }, []);
  const recipe = result && params && result.find((recipe) => recipe.id === params.recipeId);
  const ingredients = recipe && Object.keys(recipe.ingredientAmounts).map((i) => ({
    name: i,
    amount: `${recipe.ingredientAmounts[i]} ${startCase(i)}`,
  }));
  const steps = recipe && recipe.steps.map((step) => ({
    ...step,
    imgSrc: null,
  }));
  const saveAs = (uri, filename) => {
    const link = document.createElement('a');
    if (typeof link.download === 'string') {
      link.href = uri;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(uri);
    }
  };
  return (
    <Box>
      <Nav />
      <Box>
        <Box pb="32px">
          <Flex alignItems="center" justifyContent="space-between">
            <Link to="/recipes">← Back to Recipes</Link>
            {/* <SecondaryButton
              sm
              onClick={() => {
                html2canvas(document.querySelector('#recipe-card')).then((canvas) => {
                  saveAs(canvas.toDataURL(), 'download.jpg');
                });
              }}
            >
              Download as image
            </SecondaryButton> */}
          </Flex>
        </Box>
        {recipe && (
          <div id="recipe-card">
            <RecipeCard
              name={recipe.name}
              author={recipe.author}
              duration={recipe.duration}
              servingSize={recipe.servingSize}
              description={recipe.description}
              ingredients={ingredients}
              steps={steps}
            />
          </div>
        )}
      </Box>
    </Box>
  );
};

export default withRouter(Recipe);
