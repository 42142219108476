import React from 'react';
import styled from 'styled-components';
import {
  Box, Flex, Image, Text,
} from 'rebass';
import motif from 'assets/screenshots/motif.png';
import instagram from 'assets/social/instagram.png';
import stepZero from 'assets/screenshots/00.png';
import stepZeroTwo from 'assets/screenshots/00_2.png';
import stepOne from 'assets/screenshots/01.png';
import stepTwo from 'assets/screenshots/02.png';
import stepThree from 'assets/screenshots/03.png';
import stepFour from 'assets/screenshots/04.png';
import stepFive from 'assets/screenshots/05.png';
import stepSix from 'assets/screenshots/06.png';
import stepSeven from 'assets/screenshots/07.png';
import demo from 'assets/screenshots/demo.gif';
import SeeRecipesButton from 'components/SeeRecipesButton';
import JoinButton from 'components/JoinButton';
import Nav from 'components/shared/Nav';
import Carousel from 'components/shared/Carousel';

const BodyLarge = styled(Text)`
  font-size: 20px;
  line-height: 1.4;
`;

const Landing = () => <div>
  <Nav hideLogo />
  <Box mt="16px">
    <Flex flexDirection={['column', 'row']} alignItems="center">
      <Box width={[1, 0.5]}>
        <Text textAlign={['center', 'left']}>
          <Box mt="16px">
            <h1>Cook with pictures</h1>
          </Box>
          <Box mt="16px">
            <BodyLarge textAlign={['justify', 'left']}>
              Recipes too lengthy, wordy? Try something visual. Join the mailing list to get latest recipes.
            </BodyLarge>
          </Box>
        </Text>
        <Box mt="32px">
          <Flex flexDirection="row" alignItems="center" justifyContent={['center', 'stretch']}>
            <Box pr="16px">
              <JoinButton />
            </Box>
            <SeeRecipesButton />
          </Flex>
        </Box>
      </Box>
      <Box width={[1, 0.5]} mt={['32px', '0px']}>
        <Carousel
          initialSlide={0}
          slides={[
            <Image key="zero" src={stepZero} width={['240', '340']} />,
            <Image key="zero-two" src={stepZeroTwo} width={['240', '340']} />,
            <Image key="one" src={stepOne} width={['240', '340']} />,
            <Image key="two" src={stepTwo} width={['240', '340']} />,
            <Image key="three" src={stepThree} width={['240', '340']} />,
            <Image key="four" src={stepFour} width={['240', '340']} />,
            <Image key="five" src={stepFive} width={['240', '340']} />,
            <Image key="six" src={stepSix} width={['240', '340']} />,
            <Image key="seven" src={stepSeven} width={['240', '340']} />,
          ]}
        >
        </Carousel>
      </Box>
    </Flex>
  </Box>
  <Box mt="72px">
    <Flex justifyContent="center">
      <a href="https://www.producthunt.com/posts/picture-cook?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-picture-cook" target="_blank" rel="noopener noreferrer">
        <img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=199263&theme=light" alt="Picture Cook - Cook with pictures | Product Hunt Embed" style={{ width: 250, height: 54 }} width="250px" height="54px" />
      </a>
    </Flex>
  </Box>
  <Box mt="144px">
    <Flex flexDirection={['column', 'row']} alignItems={['stretch', 'center']}>
      <Box width={[1, 1 / 2]}>
        <Flex justifyContent={['center', 'flex-start']}>
          <Image src={motif} width={['280px', '440px']} height={['136px', '214px']} />
        </Flex>
      </Box>
      <Box width={[1, 1 / 2]} mt={['24px', '0px']}>
        <Flex flexDirection={['column']} alignItems={['center', 'flex-start']}>
          <h3>Cooking is meant to be fun!</h3>
          <Box mt="16px">
            <BodyLarge textAlign={['justify', 'left']}>
              Recipes are often too wordy, and make you scroll up and down the page.
              It can drive you nuts! Picture Cook offers you a visual alternative.
            </BodyLarge>
          </Box>
        </Flex>
      </Box>
    </Flex>
  </Box>
  <Box mt="144px">
    <Flex flexDirection={['column', 'row']} alignItems={['stretch', 'center']}>
      <Box width={[1, 1 / 2]} mt={['24px', '0px']}>
        <Flex flexDirection={['column']} alignItems={['center', 'flex-start']}>
          <h3>How are these recipes made?</h3>
          <Box mt="16px">
            <BodyLarge textAlign={['justify', 'left']}>
              Each recipe is carefully hand-drawn and then put together by Picture Cook software!
            </BodyLarge>
            <Box mt="16px">
              <Flex justifyContent={['center', 'flex-start']}>
                <SeeRecipesButton />
              </Flex>
            </Box>
          </Box>
        </Flex>
      </Box>
      <Box width={[1, 1 / 2]} mt={['32px', '0px']}>
        <Flex justifyContent={['center', 'flex-start']}>
          <Image src={demo} width={['280px', '440px']} height={['136px', '214px']} />
        </Flex>
      </Box>
    </Flex>
  </Box>
  <Box mt="144px">
    <Flex flexDirection={['column', 'row']} alignItems={['stretch', 'center']} justifyContent={['flex-start', 'center']}>
      <Box width={[1, 1 / 4]} mr="16px">
        <Flex justifyContent={['center', 'flex-start']}>
          <a href="https://www.instagram.com/picturecookcom/" target='_blank' rel="noopener noreferrer">
            <Image src={instagram} width={['180px', '210px']} height={['180px', '210px']} />
          </a>
        </Flex>
      </Box>
      <Box width={[1, 1 / 2]} mt={['24px', '0px']}>
        <Flex flexDirection={['column']} alignItems={['center', 'flex-start']}>
          <Text as="h3" textAlign={['center', 'left']}>Prefer Instagram over email?</Text>
          <Box mt="16px">
            <BodyLarge textAlign={['center', 'left']}>
              No problem, just <a href="https://www.instagram.com/picturecookcom/" target='_blank' rel="noopener noreferrer">follow Picture Cook on Instagram</a>
            </BodyLarge>
          </Box>
        </Flex>
      </Box>
    </Flex>
  </Box>
</div>;

export default Landing;
