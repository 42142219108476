import React from 'react';
import clock from 'assets/clock.svg';
import { Flex, Box } from 'rebass';
import { DontWrap } from 'components/shared/layout';

const Time = ({ mins, sm }) => {
  const units = typeof mins === 'string' ? '' : 'mins';
  return (
    <DontWrap>
      <Flex>
        <Box style={{ marginTop: sm ? 0 : 4 }} pr="4px">
          <img alt="Time" src={clock} width="16px" />
        </Box>
        {!sm && <div>{mins} {units}</div>}
        {sm && <small>{mins} {units}</small>}
      </Flex>
    </DontWrap>
  );
};

export default Time;
