import React from 'react';
import styled from 'styled-components';
import logo from 'assets/logo.svg';
import { Box, Flex } from 'rebass';
import { useHistory } from 'react-router-dom';
import Display from 'components/shared/Display';

const LogoOuter = styled.div`
  cursor: pointer;
`;
const LogoText = styled.div`
  font-family: 'Bubblegum Sans', cursive;
  font-size: 20px;
  white-space: nowrap;
  @media screen and (min-width: 768px) {
    font-size: 32px;
  }
`;
const LogoTagline = styled.h5`
  font-size: 12px;
  @media screen and (min-width: 768px) {
    font-size: 16px;
  }
`;
const LogoImg = styled.img`
  width: 40px;
  @media screen and (min-width: 768px) {
    width: 64px;
  }
`;

const Logo = () => {
  const history = useHistory();
  return (
    <LogoOuter onClick={() => { history.push('/'); }}>
      <Flex flexDirection="row" alignItems="center">
        <Box pr={['8px', '16px']}>
          <LogoImg alt="logo" src={logo} />
        </Box>
        <div>
          <LogoText>
            Picture Cook
          </LogoText>
          <Display breakpoints={['tablet', 'desktop']}>
            <LogoTagline>
            Cook with pictures
            </LogoTagline>
          </Display>
        </div>
      </Flex>
    </LogoOuter>
  );
};

export default Logo;
