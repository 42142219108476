import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from 'rebass/styled-components';
import pepper from '../../assets/ingredient/pepper.svg';
import aubergine from '../../assets/ingredient/aubergine.svg';
import tomato from '../../assets/ingredient/tomato.svg';

const Heading = styled.h3`
  text-align: center;
  font-family: 'Bubblegum Sans', cursive;
`;

const LoadingOuter = styled.div`
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(5deg);
    }
    50% {
      transform: rotate(0deg);
    }
    75% {
      transform: rotate(-5deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

  animation: 2s ease-out infinite spinner;
`;

const Loading = () => (
  <div>
    <Flex alignItems="center">
      <Box pr="16px">
        <LoadingOuter>
          <img
            alt="Pepper"
            src={pepper}
            width="48px"
          />
        </LoadingOuter>
      </Box>
      <Box pr="16px">
        <LoadingOuter>
          <img
            alt="Aubergine"
            src={aubergine}
            width="64px"
          />
        </LoadingOuter>
      </Box>
      <LoadingOuter>
        <img
          alt="Tomato"
          src={tomato}
          width="48px"
        />
      </LoadingOuter>
    </Flex>
    <Box pt="24px">
      <Heading>Loading...</Heading>
    </Box>
  </div>
);

export default Loading;
