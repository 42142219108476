import React from 'react';
import { Box } from 'rebass';
import Nav from 'components/shared/Nav';

const FAQ = ({ question, answer }) => (
  <Box pb="24px">
    <Box pb="8px">
      <h3>{question}</h3>
    </Box>
    <p>{answer}</p>
  </Box>
);

const FAQs = () => (
  <Box>
    <Nav />
    <Box pb="32px">
      <h1>FAQs</h1>
    </Box>
    <FAQ question="Who's working on Picture Cook?" answer="I'm Mario and I make software. This website is my personal project!" />
    <FAQ question="Why Picture Cook?" answer="I like cooking but often struggle with recipes that are wordy or lack visuals. This website is an attempt to make cooking more fun and visual." />
    <FAQ question="Can I add my own recipes?" answer={(
      <p>You can apply to become a recipe creator! We have a graphical recipe editor where you can create your own recipes. To get access to it, drop me a message at <a href="mailto:hello@picturecook.com">hello@picturecook.com</a>.</p>
    )} />
    <FAQ question="Where did you get the illustrations in the example from?" answer="All illustrations are hand-drawn!" />
    <FAQ question="How can I give feedback?" answer={(
      <p>
        Your feedback is very welcome. Please feel free to drop me a message at <a href="mailto:hello@picturecook.com">hello@picturecook.com</a>.
      </p>
    )} />
  </Box>
);

export default FAQs;
