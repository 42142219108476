import React from 'react';
import { Box, Flex } from 'rebass';
import { Link } from 'react-router-dom';
import Logo from 'components/Logo';
import JoinButton from '../JoinButton';

const Nav = () => <Box pt="24px" pb="32px">
  <Flex alignItems="center" justifyContent="space-between">
    <Logo />
    <Flex alignItems="center">
      <Box pr="16px"><Link to="/recipes">Recipes</Link></Box>
      <Box pr="16px"><Link to="/faqs">FAQs</Link></Box>
      <JoinButton />
    </Flex>
  </Flex>
</Box>;

export default Nav;
