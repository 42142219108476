/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';
import { darken } from 'polished';
import { BLACK, PRIMARY } from 'constants/colors';

const Button = styled.button`
  height: ${({ sm }) => (sm ? '36px' : '48px')};
  padding: 12px 16px;
  font-size: 16px;
  border: none;
  border-radius: 8px;
  outline: none;
  padding: ${({ sm }) => (sm ? '6px 12px' : '8px 16px')};
  font-size: ${({ sm }) => (sm ? '12px' : '16px')};
  &:hover {
    opactiy: 0.8;
    cursor: pointer;
  }
`;

export const PrimaryButton = styled(Button)`
  background-color: ${PRIMARY.base};
  color: #fff;
  &:hover {
    background-color: ${darken(0.2, PRIMARY.base)};
  }
`;

export const SecondaryButton = styled(Button)`
  background-color: #fff;
  color: ${BLACK.base};
  border: 1px solid ${BLACK.base};
  &:hover {
    background-color: ${darken(0.2, '#fff')};
  }
`;
